exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-0-genesis-index-mdx": () => import("./../../../src/pages/blog/{Mdx.frontmatter__slug}.jsx?__contentFilePath=/workspace/blog/0-genesis/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-0-genesis-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-1-payments-card-transactions-1-index-mdx": () => import("./../../../src/pages/blog/{Mdx.frontmatter__slug}.jsx?__contentFilePath=/workspace/blog/1-payments-card-transactions-1/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-1-payments-card-transactions-1-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-2-payments-card-transactions-2-index-mdx": () => import("./../../../src/pages/blog/{Mdx.frontmatter__slug}.jsx?__contentFilePath=/workspace/blog/2-payments-card-transactions-2/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-2-payments-card-transactions-2-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-3-payments-card-transactions-3-index-mdx": () => import("./../../../src/pages/blog/{Mdx.frontmatter__slug}.jsx?__contentFilePath=/workspace/blog/3-payments-card-transactions-3/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-3-payments-card-transactions-3-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-4-golang-almost-perfect-index-mdx": () => import("./../../../src/pages/blog/{Mdx.frontmatter__slug}.jsx?__contentFilePath=/workspace/blog/4-golang-almost-perfect/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-4-golang-almost-perfect-index-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

